.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.study-wrapper .intro-container {
  position: relative;
  padding-bottom: 20.83333333vw;
  background-color: #fff;
}
.study-wrapper .intro-container .data-banner-wrapper {
  box-sizing: border-box;
  height: 37.47916667vw;
  padding-top: 5vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16253074489026573.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.study-wrapper .intro-container .data-banner-wrapper h2.title {
  margin: 0 auto;
  text-align: center;
  line-height: 1.38888889;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.study-wrapper .intro-container .data-banner-wrapper > .desc {
  margin-bottom: 2.55208333vw;
  text-align: center;
  line-height: 1.38888889;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 23.4375vw;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .num-line {
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .num-line .num {
  line-height: 3.54166667vw;
  font-size: 3.125vw;
  font-family: DINCond-Light, DINCond;
  font-weight: 300;
  color: #ffffff;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .num-line .unit,
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .num-line .plus {
  line-height: 1.4;
  font-size: 1.04166667vw;
  font-family: PingFangSC-Thin, PingFang SC;
  font-weight: 100;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .num-line .unit {
  margin-bottom: 0.52083333vw;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .num-line .plus {
  align-self: center;
}
.study-wrapper .intro-container .data-banner-wrapper .data-box .data-item .text-line {
  line-height: 1.38888889;
  font-size: 0.9375vw;
  font-size: 18px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
}
.study-wrapper .intro-container .go_form {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translateX(-50%);
  width: 25.833333333333336vw;
  height: 3.125vw;
  border-radius: 1.5625vw;
  line-height: 3.125vw;
  background: #ff6600;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
}
.study-wrapper .intro-container .go_form .btn {
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.study-wrapper .intro-container .lecturer-box {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 75vw;
}
.study-wrapper .intro-container .lecturer-box .title {
  margin-top: 3.229166666666667vw;
  line-height: 1.38888889;
  text-align: center;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.study-wrapper .intro-container .lecturer-box .swiper {
  position: relative;
  margin: 2.70833333vw auto 0;
}
.study-wrapper .intro-container .lecturer-box .swiper /deep/ .swiper-wrapper {
  height: 27.08333333vw;
}
.study-wrapper .intro-container .lecturer-box .swiper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.29166667vw;
  height: 2.29166667vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.study-wrapper .intro-container .lecturer-box .swiper .swiper-arrow.prev {
  left: -2.29166667vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
}
.study-wrapper .intro-container .lecturer-box .swiper .swiper-arrow.next {
  right: -2.29166667vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item {
  box-sizing: border-box;
  position: relative;
  width: 14.16666667vw;
  height: 100%;
  border-radius: 0.83333333vw;
  overflow: hidden;
  cursor: auto;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item:hover .mask {
  background-color: rgba(0, 9, 42, 0.56);
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item:hover .titles-box {
  visibility: hidden;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item:hover .desc-box {
  visibility: visible;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.83333333vw;
  background: linear-gradient(180deg, rgba(0, 9, 42, 0) 0%, rgba(0, 9, 42, 0.77) 100%);
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .main {
  width: 100%;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .titles-box {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 1.04166667vw;
  padding-bottom: 0.88541667vw;
  color: #fff;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .titles-box .name {
  margin-bottom: 0.20833333vw;
  line-height: 1.4;
  font-size: 1.04166667vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .titles-box .titles {
  margin: 0;
  line-height: 1.4;
  font-size: 0.72916667vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  opacity: 0.8;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .desc-box {
  visibility: hidden;
  position: absolute;
  bottom: 1.0416666666666665vw;
  left: 0;
  padding: 0 1.04166667vw;
  color: #fff;
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .desc-box .name {
  margin-bottom: 0.72916667vw;
  font-size: 1.04166667vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.4;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.study-wrapper .intro-container .lecturer-box .swiper .card-item .desc-box .desc {
  font-size: 0.72916667vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
  line-height: 1.4;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  opacity: 0.8;
}
.study-wrapper .courses-container {
  padding-top: 4.89583333vw;
  padding-bottom: 3.958333333333333vw;
}
.study-wrapper .courses-container > h2.title {
  margin: 0;
  margin-bottom: 2.08333333vw;
  text-align: center;
  line-height: 1.38888889;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.study-wrapper .courses-container .points {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.02083333vw;
}
.study-wrapper .courses-container .points .point-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.study-wrapper .courses-container .points .point-item .icon {
  width: 4.16666667vw;
  height: 4.16666667vw;
  margin-right: 0.20833333vw;
}
.study-wrapper .courses-container .points .point-item .title {
  margin-bottom: 0.15625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
}
.study-wrapper .courses-container .points .point-item .desc {
  text-align: center;
  line-height: 1.4;
  font-size: 0.72916667vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.study-wrapper .courses-container .points .point-item:nth-child(1) {
  margin-right: 7.03125vw;
}
.study-wrapper .courses-container .points .point-item:nth-child(2) {
  margin-right: 6.40625vw;
}
.study-wrapper .courses-container .points .point-item:nth-child(3) {
  margin-right: 6.77083333vw;
}
.study-wrapper .courses-container .courses {
  display: flex;
  flex-wrap: wrap;
  width: 75vw;
  margin: 0 auto;
}
.study-wrapper .courses-container .courses .course-item {
  box-sizing: border-box;
  width: 21.875vw;
  height: 27.864583333333332vw;
  margin-right: 4.6875vw;
  margin-bottom: 5.20833333vw;
  border-radius: 0.41666667vw;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.study-wrapper .courses-container .courses .course-item:nth-child(3n) {
  margin-right: 0;
}
.study-wrapper .courses-container .courses .course-item:nth-last-child(-n + 3) {
  margin-bottom: 0;
}
.study-wrapper .courses-container .courses .course-item:hover {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
}
.study-wrapper .courses-container .courses .course-item .poster {
  box-sizing: border-box;
  position: relative;
  width: 21.875vw;
  height: 12.291666666666666vw;
  cursor: pointer;
}
.study-wrapper .courses-container .courses .course-item .poster .main {
  width: 100%;
  border-radius: 0.41666667vw 0.41666667vw 0 0;
}
.study-wrapper .courses-container .courses .course-item .poster .play-icon {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3.125vw;
  height: 3.125vw;
}
.study-wrapper .courses-container .courses .course-item .poster:hover .play-icon {
  visibility: visible;
}
.study-wrapper .courses-container .courses .course-item .info {
  box-sizing: border-box;
  padding: 1.04166667vw;
}
.study-wrapper .courses-container .courses .course-item .info .top {
  display: flex;
  align-items: center;
  margin-bottom: 0.72916667vw;
  justify-content: space-between;
}
.study-wrapper .courses-container .courses .course-item .info .top .speaker {
  display: flex;
  align-items: center;
}
.study-wrapper .courses-container .courses .course-item .info .top .speaker .avatar {
  width: 2.5vw;
  height: 2.5vw;
  margin-right: 0.52083333vw;
  border-radius: 50%;
  object-fit: cover;
}
.study-wrapper .courses-container .courses .course-item .info .top .speaker .name {
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.study-wrapper .courses-container .courses .course-item .info .top .type {
  height: 1.1458333333333333vw;
}
.study-wrapper .courses-container .courses .course-item .info .top .type img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.study-wrapper .courses-container .courses .course-item .info .title {
  margin-bottom: 0.52083333vw;
  line-height: 1.66666667;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.study-wrapper .courses-container .courses .course-item .info .desc .desc-item {
  line-height: 1.5625vw;
  font-size: 0.72916667vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.study-wrapper .courses-container .courses .course-item .info .btn {
  height: 2.08333333vw;
  line-height: 2.08333333vw;
  text-align: center;
  margin-top: 1.04166667vw;
  border-radius: 0.20833333vw;
  border: 1px solid #ff6600;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6600;
  cursor: pointer;
}
.study-wrapper .courses-container .courses .course-item .info .btn:hover {
  font-weight: 600;
}
.study-wrapper .courses-container .more {
  width: 12.5vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  margin: 3.125vw auto 0;
  background: #ff6600;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.study-wrapper .courses-container .more:hover {
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}
.study-wrapper .courses-container .more-box {
  margin: 3.125vw auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.study-wrapper .courses-container .more-box .qrcode-box {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.study-wrapper .courses-container .more-box .text_color {
  color: #ff6600;
}
.study-wrapper .courses-container .more-box .text {
  line-height: 1.7187500000000002vw;
  height: 5.104166666666667vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.study-wrapper .courses-container .more-box .go_form {
  width: 11.458333333333332vw;
  height: 3.125vw;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  line-height: 3.125vw;
  text-align: center;
  background: #ff6600;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.qrcode-modal {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcode-modal .qrcode-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qrcode-modal .qrcode-box .text {
  margin-bottom: 16px;
  line-height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.qrcode-modal .qrcode-box .qrcode {
  width: 260px;
  height: 260px;
  margin-bottom: 60px;
}
.qrcode-modal .qrcode-box .close-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
