
































































































































































































.box {
  display: flex;
  align-items: center;
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 9px;
  }
  .text {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff6600;
  }
}
.anchor-container {
  .section {
    transition: all 0.5s;
    &:nth-child(1) {
      padding-top: 150px;
    }
    &:nth-child(2) {
      padding-top: 60px;
      background-color: #fff;
    }
    &:nth-child(3) {
      padding-top: 60px;
      background-color: #fff;
    }
  }
}
