.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-creative {
  0% {
    opacity: 0;
    transform: translate3d(0, 90px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
.fade-in-creative {
  animation: fade-in-creative 1s ease 0s normal both;
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}
.service-wrapper .banner-wrapper {
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35.41666666666667vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16251232440022854.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.service-wrapper .banner-wrapper h1.title {
  margin-bottom: 0.6770833333333334vw;
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
}
.service-wrapper .banner-wrapper .desc {
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}
.service-wrapper .banner-wrapper .actions {
  display: flex;
  align-items: center;
  margin-top: 4.010416666666667vw;
}
.service-wrapper .banner-wrapper .actions .btn {
  box-sizing: border-box;
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  margin-right: 2.083333333333333vw;
  border: 1px solid #fff;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}
.service-wrapper .banner-wrapper .actions .btn:last-child {
  margin-right: 0;
}
.service-wrapper .banner-wrapper .actions .btn:hover {
  border: none;
  background-color: #fff;
  color: #333;
}
.service-wrapper .banner-wrapper .actions .btn.btn-white {
  background-color: #fff;
  color: #ff6600;
}
.service-wrapper .banner-wrapper .actions .btn.btn-white:hover {
  background-color: #ff6600;
  color: #fff;
}
.service-wrapper .content-wrapper {
  padding-top: 4.791666666666667vw;
  background-color: #f8f8f8;
}
.service-wrapper .content-wrapper h2.title {
  margin: 0 auto 0.41666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.service-wrapper .content-wrapper .content-desc {
  margin-bottom: 9.479166666666666vw;
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.service-wrapper .content-wrapper .select-group {
  position: relative;
  box-sizing: border-box;
  padding-top: 6.145833333333333vw;
  padding-left: 12.5vw;
  padding-bottom: 7.291666666666667vw;
  border-top-left-radius: 2.08333333vw;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.service-wrapper .content-wrapper .select-group > .title {
  display: flex;
  align-items: center;
}
.service-wrapper .content-wrapper .select-group > .title .icon {
  width: 2.8125vw;
  height: 2.3958333333333335vw;
  margin-right: 0.5208333333333333vw;
}
.service-wrapper .content-wrapper .select-group > .title .text {
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.service-wrapper .content-wrapper .select-group > .desc {
  margin-top: 2.9166666666666665vw;
}
.service-wrapper .content-wrapper .select-group > .desc .desc-item {
  margin-bottom: 2.604166666666667vw;
}
.service-wrapper .content-wrapper .select-group > .desc .desc-item .title {
  margin-bottom: 0.4166666666666667vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.service-wrapper .content-wrapper .select-group > .desc .desc-item .text {
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.service-wrapper .content-wrapper .select-group .main {
  position: absolute;
  top: -4.0625vw;
  right: 12.5vw;
  width: 40.625vw;
  height: 31.25vw;
  border-radius: 0.8333333333333334vw;
  background-color: #001554;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256410046946780.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.service-wrapper .content-wrapper .select-group .main > .img {
  background-size: cover;
  background-repeat: no-repeat;
}
.service-wrapper .content-wrapper .select-group .main .img-scene {
  position: absolute;
  top: 2.03125vw;
  left: -6.041666666666667vw;
  width: 15.052083333333332vw;
  height: 15.052083333333332vw;
  background-color: #001554;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252082851189123.png);
  border-radius: 50%;
  overflow: hidden;
}
.service-wrapper .content-wrapper .select-group .main .img-data {
  position: absolute;
  top: 5.260416666666667vw;
  left: 3.854166666666667vw;
  width: 33.958333333333336vw;
  height: 21.5625vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252082851234078.png);
}
.service-wrapper .content-wrapper .tab-section {
  padding-top: 4.166666666666666vw;
  padding-bottom: 5.416666666666667vw;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.service-wrapper .content-wrapper .tab-section .title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 3.22916667vw;
}
.service-wrapper .content-wrapper .tab-section .title .icon {
  width: 2.7083333333333335vw;
  height: 2.5vw;
  margin-right: 0.46875vw;
}
.service-wrapper .content-wrapper .tab-section .title .text {
  line-height: 2.083333333333333vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.service-wrapper .content-wrapper .tab-section .tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 26.979166666666664vw;
  margin-right: 30.15625vw;
}
.service-wrapper .content-wrapper .tab-section .tabs .tab-item {
  position: relative;
  padding-bottom: 0.9895833333333333vw;
  line-height: 1.4;
  font-size: 1.5625vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333;
}
.service-wrapper .content-wrapper .tab-section .tabs .tab-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3.75vw;
  height: 0.3125vw;
  border-radius: 0.15625vw;
  background-color: #ddd;
}
.service-wrapper .content-wrapper .tab-section .tabs .tab-item.active::after {
  background-color: #ff6600;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75vw;
  height: 31.25vw;
  margin: 2.39583333vw auto 0;
  border-radius: 0.8333333333333334vw;
  background-color: #001554;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-scrm {
  background-image: url(https://mktv-in-cdn.mockuai.com/16252126301467595.png);
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-scrm .banner {
  top: 3.5937499999999996vw;
  left: 4.21875vw;
  width: 25.260416666666668vw;
  height: 25.260416666666668vw;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-marketing {
  background-image: url(https://mktv-in-cdn.mockuai.com/16252126301346535.png);
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-marketing .banner {
  top: 0.9375vw;
  left: 6.302083333333333vw;
  width: 21.40625vw;
  height: 30.312499999999996vw;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-marketing .info .desc {
  margin-bottom: 5.208333333333334vw;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .banner {
  position: absolute;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info {
  margin-left: 37.5vw;
  width: 28.645833333333332vw;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .desc {
  margin: 0;
  margin-bottom: 2.4479166666666665vw;
  line-height: 2.083333333333333vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item {
  display: flex;
  align-items: center;
  margin-right: 2.083333333333333vw;
  margin-bottom: 1.3541666666666667vw;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item:nth-child(4n) {
  margin-right: 0;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.5208333333333333vw;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item .text {
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #fff;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .more {
  display: block;
  text-decoration: none;
  margin-top: 1.7187500000000002vw;
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #ff6600;
  cursor: pointer;
}
.service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .more:hover {
  font-weight: 600;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel {
  position: relative;
  box-sizing: border-box;
  width: 75vw;
  height: 31.25vw;
  margin: 2.5vw auto 0;
  padding-top: 7.8125vw;
  padding-left: 4.739583333333333vw;
  border-radius: 0.8333333333333334vw;
  background-color: #001554;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel.panel-storage {
  background-image: url(https://mktv-in-cdn.mockuai.com/16309803039358500.png);
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel.panel-storage .banner {
  top: 3.5937499999999996vw;
  right: 4.114583333333333vw;
  width: 29.947916666666668vw;
  height: 25.208333333333332vw;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel.panel-customer {
  padding-top: 9.0625vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252235252714282.png);
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .desc {
  margin-bottom: 1.0416666666666665vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .points .point-item {
  line-height: 2.604166666666667vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #ffffff;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .more {
  display: block;
  text-decoration: none;
  margin-top: 1.7187500000000002vw;
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #ff6600;
  cursor: pointer;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .more:hover {
  font-weight: 600;
}
.service-wrapper .content-wrapper .tab-section.aftersale-management .panel .banner {
  position: absolute;
}
.service-wrapper .content-wrapper .content-planning {
  position: relative;
  box-sizing: border-box;
  margin-left: 6.25vw;
  margin-bottom: 10.520833333333334vw;
  width: 93.75vw;
  height: 35.41666666666667vw;
  padding-top: 4.270833333333333vw;
  padding-bottom: 4.791666666666667vw;
  border-top-left-radius: 2.08333333vw;
  border-bottom-left-radius: 2.08333333vw;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.service-wrapper .content-wrapper .content-planning .info {
  margin-left: 6.25vw;
  width: 17.1875vw;
}
.service-wrapper .content-wrapper .content-planning .info > .title {
  display: flex;
  align-items: center;
  margin-bottom: 7.34375vw;
}
.service-wrapper .content-wrapper .content-planning .info > .title .icon {
  width: 2.5vw;
  height: 2.7083333333333335vw;
  margin-right: 0.3645833333333333vw;
}
.service-wrapper .content-wrapper .content-planning .info > .title .text {
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.service-wrapper .content-wrapper .content-planning .info .desc .desc-item {
  margin-bottom: 2.5vw;
}
.service-wrapper .content-wrapper .content-planning .info .desc .desc-item .title {
  margin-bottom: 0.4166666666666667vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.service-wrapper .content-wrapper .content-planning .info .desc .desc-item .text {
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.service-wrapper .content-wrapper .content-planning .main {
  position: absolute;
  left: 29.635416666666664vw;
  top: 4.270833333333333vw;
  display: flex;
  align-items: center;
}
.service-wrapper .content-wrapper .content-planning .main img {
  margin-right: 0.9895833333333333vw;
  background-color: #001554;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
}
.service-wrapper .content-wrapper .content-planning .main img:nth-child(1) {
  width: 14.0625vw;
}
.service-wrapper .content-wrapper .content-planning .main img:nth-child(2) {
  width: 17.760416666666668vw;
}
.service-wrapper .content-wrapper .content-planning .main img:nth-child(3) {
  width: 17.708333333333336vw;
}
.service-wrapper .content-wrapper .live-operation {
  position: relative;
  box-sizing: border-box;
  width: 87.5vw;
  height: 35.41666666666667vw;
  padding-top: 4.166666666666666vw;
  padding-bottom: 6.406249999999999vw;
  margin-bottom: 8.333333333333332vw;
  border-top-right-radius: 2.08333333vw;
  border-bottom-right-radius: 2.08333333vw;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.service-wrapper .content-wrapper .live-operation .info {
  margin-left: 53.125vw;
}
.service-wrapper .content-wrapper .live-operation .info > .title {
  display: flex;
  align-items: center;
  margin-bottom: 6.041666666666667vw;
}
.service-wrapper .content-wrapper .live-operation .info > .title .icon {
  width: 2.604166666666667vw;
  height: 2.5vw;
  margin-right: 0.9895833333333333vw;
}
.service-wrapper .content-wrapper .live-operation .info > .title .text {
  line-height: 2.083333333333333vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ff6600;
}
.service-wrapper .content-wrapper .live-operation .info .desc .desc-item {
  margin-bottom: 2.5vw;
}
.service-wrapper .content-wrapper .live-operation .info .desc .desc-item .title {
  margin-bottom: 0.4166666666666667vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.service-wrapper .content-wrapper .live-operation .info .desc .desc-item .text {
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666;
}
.service-wrapper .content-wrapper .live-operation .main {
  position: absolute;
  left: 12.5vw;
  top: 5.729166666666666vw;
  display: flex;
  align-items: center;
}
.service-wrapper .content-wrapper .live-operation .main img {
  margin-right: 0.8333333333333334vw;
  background-color: #001554;
  border-radius: 0.8333333333333334vw;
  overflow: hidden;
}
.service-wrapper .content-wrapper .live-operation .main img:nth-child(1) {
  width: 18.28125vw;
}
.service-wrapper .content-wrapper .live-operation .main img:nth-child(2) {
  width: 16.145833333333336vw;
}
@media screen and (max-width: 1066px) {
  .service-wrapper .banner-wrapper {
    height: 377.531px;
  }
  .service-wrapper .banner-wrapper h1.title {
    width: 677.3px;
    margin-bottom: 7.21771px;
    font-size: 33.3125px;
  }
  .service-wrapper .banner-wrapper .desc {
    line-height: 18.3219px;
    font-size: 13.325px;
  }
  .service-wrapper .banner-wrapper .actions .btn {
    margin-right: 22.2083px;
    width: 122.141px;
    height: 33.3125px;
    line-height: 33.3125px;
    border-radius: 16.6562px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper {
    padding-top: 51.079px;
  }
  .service-wrapper .content-wrapper h2.title {
    margin-bottom: 4.442px;
    line-height: 27.7604px;
    font-size: 19.9875px;
  }
  .service-wrapper .content-wrapper .content-desc {
    margin-bottom: 101.048px;
    line-height: 13.8802px;
    font-size: 10px;
  }
  .service-wrapper .content-wrapper .select-group {
    padding-top: 65.5146px;
    padding-left: 133.25px;
    padding-bottom: 77.7292px;
    border-top-left-radius: 22.2083px;
  }
  .service-wrapper .content-wrapper .select-group > .title .icon {
    width: 29.969px;
    height: 25.531px;
    margin-right: 5.55208px;
  }
  .service-wrapper .content-wrapper .select-group > .title .text {
    font-size: 19.9875px;
  }
  .service-wrapper .content-wrapper .select-group > .desc {
    margin-top: 31.0917px;
  }
  .service-wrapper .content-wrapper .select-group > .desc .desc-item {
    margin-bottom: 27.7604px;
  }
  .service-wrapper .content-wrapper .select-group > .desc .desc-item .title {
    margin-bottom: 4.44167px;
    line-height: 18.3219px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .select-group > .desc .desc-item .text {
    line-height: 16.6562px;
    font-size: 10px;
  }
  .service-wrapper .content-wrapper .select-group .main {
    top: -43.3063px;
    right: 133.25px;
    width: 433.062px;
    height: 333.125px;
    border-radius: 8.88333px;
  }
  .service-wrapper .content-wrapper .select-group .main .img-scene {
    top: 21.6531px;
    left: -64.4042px;
    width: 160.453px;
    height: 160.453px;
  }
  .service-wrapper .content-wrapper .select-group .main .img-data {
    top: 56.076px;
    left: 41.0854px;
    width: 361.984px;
    height: 229.844px;
  }
  .service-wrapper .content-wrapper .tab-section {
    padding-top: 44.4167px;
    padding-bottom: 57.7417px;
  }
  .service-wrapper .content-wrapper .tab-section .title {
    margin-bottom: 34.4229px;
  }
  .service-wrapper .content-wrapper .tab-section .title .icon {
    width: 28.8594px;
    height: 26.6406px;
    margin-right: 4.99687px;
  }
  .service-wrapper .content-wrapper .tab-section .title .text {
    line-height: 22.2083px;
    font-size: 19.9875px;
  }
  .service-wrapper .content-wrapper .tab-section .tabs {
    margin-left: 287.598px;
    margin-right: 321.466px;
  }
  .service-wrapper .content-wrapper .tab-section .tabs .tab-item {
    padding-bottom: 10.549px;
    font-size: 16.6562px;
  }
  .service-wrapper .content-wrapper .tab-section .tabs .tab-item::after {
    width: 39.9688px;
    height: 3.32812px;
    border-radius: 1.66562px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel {
    width: 799.5px;
    height: 333.125px;
    margin-top: 25.5396px;
    border-radius: 8.88333px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-scrm .banner {
    top: 38.3094px;
    left: 44.9719px;
    width: 269.266px;
    height: 269.266px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-marketing .banner {
    top: 9.99375px;
    left: 67.1802px;
    width: 228.188px;
    height: 323.125px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel.panel-marketing .info .desc {
    margin-bottom: 55.5208px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info {
    margin-left: 399.75px;
    width: 305.359px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .desc {
    margin-bottom: 26.0948px;
    line-height: 22.2083px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item {
    margin-right: 22.2083px;
    margin-bottom: 14.4354px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item .icon {
    width: 11.0938px;
    height: 8.32812px;
    margin-right: 5.55208px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .services .service-item .text {
    line-height: 13.8802px;
    font-size: 10px;
  }
  .service-wrapper .content-wrapper .tab-section.traffic-operation .panel .info .more {
    margin-top: 18.3219px;
    width: 122.141px;
    height: 33.3125px;
    line-height: 33.3125px;
    border-radius: 16.6562px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .tab-section.aftersale-management .panel {
    width: 799.5px;
    height: 333.125px;
    margin-top: 26.65px;
    padding-top: 83.2812px;
    padding-left: 50.524px;
    border-radius: 8.88333px;
  }
  .service-wrapper .content-wrapper .tab-section.aftersale-management .panel.panel-storage .banner {
    top: 38.3094px;
    right: 43.8615px;
    width: 319.234px;
    height: 268.719px;
  }
  .service-wrapper .content-wrapper .tab-section.aftersale-management .panel.panel-customer {
    padding-top: 96.6062px;
  }
  .service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .desc {
    margin-bottom: 11.1042px;
    line-height: 18.3219px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .points .point-item {
    line-height: 27.7604px;
    font-size: 10px;
  }
  .service-wrapper .content-wrapper .tab-section.aftersale-management .panel .info .more {
    margin-top: 18.3219px;
    width: 122.141px;
    height: 33.3125px;
    line-height: 33.3125px;
    border-radius: 16.6562px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .content-planning {
    margin-left: 66.625px;
    margin-bottom: 112.152px;
    width: 999.375px;
    height: 377.531px;
    padding-top: 45.5271px;
    padding-bottom: 51.0792px;
    border-top-left-radius: 22.2083px;
    border-bottom-left-radius: 22.2083px;
  }
  .service-wrapper .content-wrapper .content-planning .info {
    margin-left: 66.625px;
    width: 183.219px;
  }
  .service-wrapper .content-wrapper .content-planning .info .title {
    margin-bottom: 78.2844px;
  }
  .service-wrapper .content-wrapper .content-planning .info .title .icon {
    width: 26.6406px;
    height: 28.8594px;
    margin-right: 3.88646px;
  }
  .service-wrapper .content-wrapper .content-planning .info .title .text {
    font-size: 19.9875px;
  }
  .service-wrapper .content-wrapper .content-planning .info .desc .desc-item {
    margin-bottom: 26.65px;
  }
  .service-wrapper .content-wrapper .content-planning .info .desc .desc-item .title {
    margin-bottom: 4.44167px;
    line-height: 18.3219px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .content-planning .info .desc .desc-item .text {
    line-height: 16.6562px;
    font-size: 10px;
  }
  .service-wrapper .content-wrapper .content-planning .main {
    top: 45.5271px;
    left: 315.914px;
  }
  .service-wrapper .content-wrapper .content-planning .main img {
    margin-right: 10.549px;
    border-radius: 8.88333px;
  }
  .service-wrapper .content-wrapper .content-planning .main img:nth-child(1) {
    width: 149.906px;
  }
  .service-wrapper .content-wrapper .content-planning .main img:nth-child(2) {
    width: 189.312px;
  }
  .service-wrapper .content-wrapper .content-planning .main img:nth-child(3) {
    width: 188.766px;
  }
  .service-wrapper .content-wrapper .live-operation {
    width: 932.75px;
    height: 377.531px;
    padding-top: 44.4167px;
    padding-bottom: 68.2906px;
    margin-bottom: 88.8333px;
    border-top-right-radius: 22.2083px;
    border-bottom-right-radius: 22.2083px;
  }
  .service-wrapper .content-wrapper .live-operation .info {
    margin-left: 566.312px;
  }
  .service-wrapper .content-wrapper .live-operation .info .title {
    margin-bottom: 64.4042px;
  }
  .service-wrapper .content-wrapper .live-operation .info .title .icon {
    width: 27.75px;
    height: 26.6406px;
    margin-right: 10.549px;
  }
  .service-wrapper .content-wrapper .live-operation .info .title .text {
    line-height: 22.2083px;
    font-size: 19.9875px;
  }
  .service-wrapper .content-wrapper .live-operation .info .desc .desc-item {
    margin-bottom: 26.65px;
  }
  .service-wrapper .content-wrapper .live-operation .info .desc .desc-item .title {
    margin-bottom: 4.44167px;
    line-height: 18.3219px;
    font-size: 13.325px;
  }
  .service-wrapper .content-wrapper .live-operation .info .desc .desc-item .text {
    line-height: 16.6562px;
    font-size: 10px;
  }
  .service-wrapper .content-wrapper .live-operation .main {
    top: 61.0729px;
    left: 133.25px;
  }
  .service-wrapper .content-wrapper .live-operation .main img {
    margin-right: 8.88333px;
    border-radius: 8.88333px;
  }
  .service-wrapper .content-wrapper .live-operation .main img:nth-child(1) {
    width: 194.875px;
  }
  .service-wrapper .content-wrapper .live-operation .main img:nth-child(2) {
    width: 172.109px;
  }
}
